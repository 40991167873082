var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editais-data"},[_c('b-form',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.itemData.id),expression:"itemData.id"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.itemData.id)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.itemData, "id", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.itemData.evento),expression:"itemData.evento"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.itemData.evento)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.itemData, "evento", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.itemData.status),expression:"itemData.status"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.itemData.status)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.itemData, "status", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.itemData.created_at),expression:"itemData.created_at"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.itemData.created_at)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.itemData, "created_at", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.itemData.updated_at),expression:"itemData.updated_at"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.itemData.updated_at)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.itemData, "updated_at", $event.target.value)}}}),_c('b-row',[_c('b-col',{attrs:{"md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":`Nº do Edital${
            _vm.userParams.admin >= 1 && _vm.itemData.id
              ? ' (' + _vm.itemData.id + ')'
              : ''
          }`,"label-for":"editais-nr_edital"}},[_c('b-form-input',{attrs:{"id":"editais-nr_edital","type":"text","required":"","maxlength":"50","placeholder":"Informe o número do edital ..."},model:{value:(_vm.itemData.nr_edital),callback:function ($$v) {_vm.$set(_vm.itemData, "nr_edital", $$v)},expression:"itemData.nr_edital"}})],1)],1),_c('b-col',{attrs:{"md":"8","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Descrição Curta","label-for":"editais-descricao_curta"}},[_c('b-form-input',{attrs:{"id":"editais-descricao_curta","type":"text","maxlength":"255","required":"","placeholder":"Descreva o edital ..."},model:{value:(_vm.itemData.descricao_curta),callback:function ($$v) {_vm.$set(_vm.itemData, "descricao_curta", $$v)},expression:"itemData.descricao_curta"}})],1)],1),_c('b-col',{attrs:{"md":_vm.itemData.id ? '8' : '12',"sm":"12"}},[_c('b-form-group',{attrs:{"label":"Descrição Longa","label-for":"editais-descricao_longa"}},[_c('VueEditor',{attrs:{"id":"editais-descricao_longa","required":"","placeholder":"Descreva o edital ..."},model:{value:(_vm.itemData.descricao_longa),callback:function ($$v) {_vm.$set(_vm.itemData, "descricao_longa", $$v)},expression:"itemData.descricao_longa"}})],1)],1),(_vm.itemData.id || _vm.formFiles)?_c('b-col',{attrs:{"md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Arquivos (jpg, png, gif, docx, xlsx, pptx, pdf, txt, zip, rar)","label-for":"editais-arquivos"}},[(_vm.itemUploads)?_c('b-form-file',{attrs:{"state":Boolean(_vm.itemUploads),"accept":".jpg, .png, .gif, .docx, .doc, .xlsx, .xls, .pptx, .ppt, .pdf, .txt, .zip, .rar","placeholder":"Escolha ou arraste arquivos para aqui...","drop-placeholder":"Arraste arquivos aqui...","multiple":"","file-name-formatter":_vm.formatNames},model:{value:(_vm.itemUploads),callback:function ($$v) {_vm.itemUploads=$$v},expression:"itemUploads"}}):_vm._e()],1),_c('div',{staticClass:"clearfix"},[(
              (this.itemFiles && this.itemFiles.length > 0) ||
              this.filesExists
            )?_c('b-row',_vm._l((_vm.itemFiles),function(file){return _c('b-col',{key:file.id,attrs:{"sm":"12","md":"12"}},[_c('b-button',{staticClass:"float-left text-left text-wrap",staticStyle:{"width":"70%"},attrs:{"variant":file.status == 10 ? 'link' : 'disabled'},on:{"click":function($event){return _vm.downloadFile(file.href, file.file_caption)}}},[_vm._v(" "+_vm._s(file.file_caption)+"."+_vm._s(file.file_ext)+" ")]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"float-right",attrs:{"variant":"danger","title":"Remover arquivo"},on:{"click":function($event){return _vm.removeFile(file)}}},[_c('i',{staticClass:"fa fa-trash"})]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"float-right",attrs:{"variant":`${file.status == 10 ? 'warning' : 'success'}`,"title":`${
                  file.status == 10 ? 'Suspender' : 'Reativar'
                } download do arquivo`},on:{"click":function($event){file.status == 10 ? _vm.suspendFile(file) : _vm.activeFile(file)}}},[(file.status == 10)?_c('i',{staticClass:"fas fa-ban"}):_c('i',{staticClass:"far fa-check-circle"})]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"float-right",attrs:{"variant":"primary","title":"Download do arquivo"},on:{"click":function($event){return _vm.downloadFile(file.href, file.file_caption)}}},[_c('i',{staticClass:"fas fa-file-download"})])],1)}),1):_vm._e()],1),(_vm.itemUploads && _vm.itemUploads.length > 0)?_c('span',[_vm._v("Arquivos a enviar")]):_vm._e(),(_vm.itemUploads && _vm.itemUploads.length > 0)?_c('ul',_vm._l((_vm.itemUploads),function(file){return _c('li',{key:file.id},[_vm._v(_vm._s(file.name))])}),0):_vm._e(),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"outline-primary"},on:{"click":_vm.submitFile}},[_vm._v(" Enviar"+_vm._s(_vm.itemUploads && _vm.itemUploads.length > 1 ? " +" : "!")+" ")])],1):_vm._e()],1),_c('div',{staticClass:"clearfix"},[(_vm.mode === 'save')?_c('b-button',{staticClass:"float-left",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.itemData.id ? "Editar" : "Salvar")+" ")]):_vm._e(),(_vm.mode === 'remove')?_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.remove}},[_vm._v(" Excluir ")]):_vm._e(),(_vm.itemData.id)?_c('b-button',{staticClass:"ml-2",on:{"click":_vm.reset}},[_vm._v("Cancelar")]):_vm._e(),(_vm.itemData.id)?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"outline-success"},on:{"click":_vm.preview}},[_vm._v("Preview da Publicação")]):_vm._e(),(_vm.itemData.hash)?_c('code',{staticClass:"float-right"},[_vm._v(" Edital: "+_vm._s(_vm.itemData.hash + _vm.itemData.tblName)+" ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }