var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"exec_contratos-grid pdd-10"},[_c('b-input-group',{staticClass:"mt-3 mb-3",attrs:{"size":"md"}},[_c('b-dropdown',{attrs:{"slot":"prepend","text":"Novo registro","variant":`outline-${_vm.lblBtnNewVariant}`},slot:"prepend"},[_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){return _vm.newRow('1')}}},[_vm._v("Licitação")]),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){return _vm.newRow('2')}}},[_vm._v("Contratação")])],1),_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],ref:"keyword",attrs:{"type":"text","placeholder":"Digite aqui para localizar o registro...","title":`Digite ao menos ${_vm.charSearchCount} caracteres para pesquisa dinâmica e use espaços para pesquisar por mais de uma expressão`},on:{"input":function($event){return _vm.searchExeCont(_vm.charSearchCount)},"blur":function($event){return _vm.searchExeCont(0)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('b-btn',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.searchExeCont(0)}}},[_c('i',{staticClass:"fa fa-search"})]),_c('b-input-group-text',{attrs:{"slot":"append"},slot:"append"},[(_vm.isBrowser)?_c('span',[_vm._v(_vm._s(_vm.keyword_res)+" ")]):_vm._e(),_c('b-btn',{staticClass:"p-0",attrs:{"disabled":!_vm.keyword,"variant":"link","size":"sm"},on:{"click":_vm.getRefresh}},[_c('i',{staticClass:"fa fa-remove"})])],1)],1),_c('b-table',{attrs:{"hover":"","striped":"","responsive":"","items":_vm.exec_contratos,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(n_licitacao)",fn:function(data){return [(data.item.n_licitacao)?_c('span',{domProps:{"innerHTML":_vm._s(
              `Licitação: ${data.item.n_licitacao} ${
                _vm.userParams.admin >= 1 ? `(${data.item.id})` : ''
              }`
            )}}):_vm._e(),(data.item.n_contratacao)?_c('span',{domProps:{"innerHTML":_vm._s(
              `Contratação: ${data.item.n_contratacao} ${
                _vm.userParams.admin >= 1 ? `(${data.item.id})` : ''
              }`
            )}}):_vm._e()]}},{key:"cell(n_contrato)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.item.n_contrato)}})]}},{key:"cell(descricao)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.item.descricao)}})]}},{key:"cell(actions)",fn:function(row){return [(_vm.userParams.id >= 1)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1",attrs:{"variant":"outline-info","size":"sm","title":"Editar registro","href":"#header"},on:{"click":function($event){return _vm.showRow(row.item, row.index, $event.target)}}},[_c('i',{staticClass:"fa fa-pencil"})]):_vm._e(),(_vm.userParams.id >= 1)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":"outline-danger","size":"sm","title":"Remover registro"},on:{"click":function($event){return _vm.remove(row.item.id)}}},[_c('i',{staticClass:"fa fa-trash"})]):_vm._e()]}}])}),_c('b-pagination',{attrs:{"size":"md","total-rows":_vm.count,"per-page":_vm.limit},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]),_c('b-modal',{attrs:{"size":"xl","id":"modal-form","title":_vm.modalTitle,"centered":"","scrollable":"","hide-footer":""}},[_c('ExecContratoData',{attrs:{"tipoProposta":_vm.objProponente,"item":_vm.exeContratoInputs},on:{"objectInputs":_vm.loadExecContratos}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }